<div id="yoreme--navbar">
    <img src="assets/images/logo-02.png" alt="" style="width: 15%;" loading="lazy">
</div>

<main class="yoreme--container">
    <div class="yoreme-video-card">
        <div style="padding:56.25% 0 0 0;"><iframe
                src="https://player.vimeo.com/video/863276744?h=306149eb7b&autoplay=1&loop=1&title=0&byline=0&portrait=0"
                style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
        <script src="https://player.vimeo.com/api/player.js"></script>
    </div>

    <div class="yoreme-content-1">
        <div class="yc-sec-1-2023 wow bounceInLeft">
            <img src="assets/images/2023/bloque-s101.png" alt="" loading="lazy">
        </div>
        <div class="yc-sec-2 wow bounceInRight">
            <img src="assets/images/bloque-s102.png" alt="" loading="lazy">
            <p>
                <b>
                    “Entre flora y fauna silvestre convergen todos los elementos que forman parte de nuestro estado, los
                    bastos desiertos, los animales y nativos, todo en un equilibrio que fluye sin fin”
                </b>
            </p>
            <p>
                Nuestra Reserva Especial 2023 es una obra pictórica original llena de elementos representativos de nuestras
                tierras sonorenses, flora y fauna silvestre unidas con el viento, la tierra y el agua. Simbolizando la 
                fuerza, la belleza y fuentes de vida y alimento.
            </p>
            <p>
                <b>“Es un homenaje a nuestras raíces y tradiciones”</b>.
            </p>
        </div>
    </div>
    <div class="yoreme-content-img">
        <img src="assets/images/bloque-s201.png" alt="" loading="lazy">
        <div class="yc-img-info">
            <h3 class="wow bounceInUp">CALIDAD CON GARANTÍA</h3>
            <p class="wow bounceInUp">
                Los cortes más exclusivos, con el grado más alto de marmoleo y suavidad. Cada año seleccionamos nuestra
                mejor reserva de <span class="bold">Rib Eye y New York</span>, para aquellos paladares sofisticados que
                no le
                temen a un sabor muy
                intenso, perfecto para acompañar con un buen vino y disfrutar de una experiencia culinaria inigualable.
            </p>
        </div>
    </div>
    <div class="bloque-3">
        <div class="row">
            <div class="aligned-center text-bloques wow bounceInLeft">
                <h3 style="font-family: zeppelin-42-regular;">COLECCIÓN</h3>
                <p>Descubre nuestra colección de Reservas Especiales,
                    vestidas con
                    obras de arte exclusivas que transmiten la
                    esencia de nuestra región, realizadas por artistas reconocidos.</p>
            </div>
            <div class="col center-card" *ngFor="let collection of this.dataCollections">
                <div class="card-b3 wow bounceInRight">
                    <img src={{collection.image}} alt="" width="100%" />
                    <br><br>
                    <h4 style="font-family: manrope-semibold;">{{collection.edition}}</h4>
                    <h6 style="font-family: manrope-semibold;">"{{collection.title}}"</h6>
                    <h6 style="font-family: manrope-semibold;">Autor: {{collection.autor}}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="bloque-4" *ngIf="false">
        <div class="row">
            <div class="col-md-4 center-card wow bounceInLeft">
                <div class="card-b4">
                    <h3 style="font-family: manrope-semibold;">Agustin Covarrubias</h3>
                    <img src="assets/images/triangles-decoration.png" alt="" width="100%" loading="lazy" />
                    <br><br>
                    <p class="card-text">
                        <strong>Carne de calidad mundial.</strong> <br>
                        De las mejores experiencias gastronómicas a la parrilla, su brillante color rojo impacta y
                        enamora al comensal, gracias a su perfecto marmoleo obliga al corte a tener una suavidad y sabor
                        espectacular, sin duda alguna la experiencia Yoreme coloca a Sonora como referencia en calidad
                        de carne.
                    </p>
                </div>
            </div>
            <div class="col-md-4 center-card wow bounceInUp">
                <div class="card-b4">
                    <h3 style="font-family: manrope-semibold;">Alberto Borquez</h3>
                    <img src="assets/images/triangles-decoration.png" alt="" width="100%" loading="lazy" />
                    <br><br>
                    <p class="card-text">Me sorprendió la calidad, los sabores tan definidos y una textura suave.
                        Dificilmente se
                        encontrará algo con ese nivel. <strong>Sin duda es la carne de México.</strong></p>
                </div>
            </div>
            <div class="col-md-4 center-card wow bounceInRight">
                <div class="card-b4">
                    <h3 style="font-family: manrope-semibold;">Fausto Alcalá</h3>
                    <img src="assets/images/triangles-decoration.png" alt="" width="100%" loading="lazy" />
                    <br><br>
                    <p class="card-text">Los cortes son de primer nivel, la caja de la Reserva es una completa obra de
                        arte y lo
                        mejor es la experiencia de tener un producto de <strong>calidad incomparable.</strong></p>
                </div>
            </div>
        </div>
    </div>
    <div class="bloque-5">
        <div class="row">
            <div class="col-md-10 col-sm-12 aligned-center wow bounceInLeft">
                <img src="assets/images/2023/info-1.png" alt="" width="100%" loading="lazy" />
                <div class="container-button">
                    <a class="fab fa-whatsapp button border-button-whatsapp" target="_blank"
                        href="https://wa.me/+5216442358407/">
                        Mandar
                        mensaje</a>
                </div> 
                <!-- <h2 class="text-black" style="font-family: zeppeling-42-regular;">
                     UNA RESERVA ESPECIAL PARA CLIENTES ESPECIALES
                </h2>
                <p class="text-black">Para adquirir tu Reserva Especial
                    2023
                    contacta a
                    nuestra representante de ventas Ailed
                    A. por medio de Whatsapp</p>
                <div class="container-button">
                    <a class="fab fa-whatsapp button border-button-whatsapp" target="_blank"
                        href="https://wa.me/+5216442358407/">
                        Mandar
                        mensaje</a>
                </div> -->

            </div>
        </div>
    </div>
    <div class="bloque-6">
        <div class="row">
            <div class="col-md-6 aligned-center wow bounceInUp">
                <img src="assets/images/2023/pre-catalogo-2.png" alt="" width="100%" loading="lazy" />
            </div>
            <div class="col-md-6 aligned-center wow bounceInUp">
                <h2 class="text-white" style="font-family: zeppeling-42-regular;">SELECCIÓN EXCLUSIVA</h2>
                <p class="text-white">Te presentamos nuestro catálogo de
                    reservas,
                    en el
                    cual podrás seleccionar tu corte de
                    nuestra selección de Rib Eye y New York</p>
                <div class="container-button">
                    <a class="button border-button" style="font-family: manrope-light;" target="_blank"
                        href="https://drive.google.com/drive/folders/1vldHUakjOnChisO21AAMY6q79DbanAa2?usp=sharing">
                        <span class="fa fa-download"></span>
                        Descargar catálogo
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>